/* ====== Logging code ====== */

import Logger from './lib/Logger';

const log = new Logger(true).log;

/*
    Initial code for installing the service worker on the web page
*/

/*
    This function registers the service worker on the web page.

    If first checks if the browser supports service workers. 
    
    If it does, it attempts to register the service worker.

*/
const registerServiceWorker = (serviceWorkerUrl, options) => {
	try {
		if ('serviceWorker' in navigator) {
			log('Registering service worker');
			navigator.serviceWorker.register(serviceWorkerUrl, options);
			log('Service worker registered');
		} else {
			log('Service worker not supported');
		}
	} catch (err) {
		log('Service worker registration failed');
		log(err);
	}
};

/*
	This function checks if the service worker should be enabled.

	It does this by checking the query string of the web page url for the 
	parameter `sw` and if it is set to `true`, it enables the service worker.
*/
const enableServiceWorker = () => {
	const searchParams = new URLSearchParams(document.location.search);
	return searchParams.get('sw') === 'true';	
}

if (enableServiceWorker()) {
	registerServiceWorker('/serviceworker.js', {type: 'module'});
}
