type Message = string | Array<any> | number | Date | object;

class Logger {

	logEnabled: boolean;

	constructor(logEnabled:boolean) {
		this.logEnabled = logEnabled;
		this.log = this.log.bind(this);
	}

	/*
		This is a wrapper around console.log that will print out a timestamp 
		alongside the message, which is useful for measuring the time between
		events.

		It also logs a message if logging is enabled. This makes it easier to
		turn off logging in production.
	*/
	log(message:Message) {
		if (!this.logEnabled) return;
		if (typeof message === 'object') {
			console.log(Date.now() + ' ' + JSON.stringify(message));
		} else {
			console.log(Date.now() + ' ' + message);
		}
	}
}

export default Logger;